import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { formDropdownData } from "../../common/utils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getSourceImages = createAsyncThunk(
  "getSourceImages",
  async (
    { useCache = true, organizationId, selectedTenant, siteName },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestIdSourceImages, loadingSourceImages, sourceImages } =
      getState().adminActions.deschutes;
    if (!loadingSourceImages || requestId !== currentRequestIdSourceImages) {
      return;
    }
    if (
      useCache &&
      sourceImages[selectedTenant] &&
      sourceImages[selectedTenant][siteName]
    ) {
      return {
        data: sourceImages[selectedTenant][siteName],
        selectedTenant,
        siteName,
      };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${encodeURI(
          siteName,
        )}/window365/deviceImages/sourceImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, siteName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDeviceImages = createAsyncThunk(
  "getDeviceImages",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      currentRequestId,
      loadingDeviceImages,
      deviceImages,
      deviceImagesAvailable,
    } = getState().adminActions.deschutes;
    if (!loadingDeviceImages || requestId !== currentRequestId) {
      return;
    }
    if (useCache && deviceImagesAvailable[selectedTenant] === true) {
      return { data: deviceImages[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/window365/deviceImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const checkCloudPc = createAsyncThunk(
  "checkCloudPc",
  async (
    { useCache = true, organizationId, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingCheckCloudPc, cloudPcEnabled } =
      getState().adminActions.deschutes;
    if (!loadingCheckCloudPc || requestId !== currentRequestId) {
      return;
    }
    if (useCache && typeof cloudPcEnabled[selectedTenant] !== "undefined") {
      return { data: cloudPcEnabled[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/cloudPCs/support`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDeviceImagesForSite = createAsyncThunk(
  "getDeviceImagesForSite",
  async (
    { useCache = true, organizationId, selectedTenant, site },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingDeviceImages, deviceImagesForSite } =
      getState().adminActions.deschutes;
    if (!loadingDeviceImages || requestId !== currentRequestId) {
      return;
    }
    if (useCache && deviceImagesForSite[site]) {
      return { data: deviceImagesForSite[site], site };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${site}/window365/deviceImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, site };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getGalleryImages = createAsyncThunk(
  "getGalleryImages",
  async (
    { useCache = true, organizationId, selectedTenant },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingGalleryImages, galleryImages } =
      getState().adminActions.deschutes;
    if (!loadingGalleryImages || requestId !== currentRequestId) {
      return;
    }
    if (useCache && galleryImages?.length) {
      return { data: galleryImages };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/window365/galleryImages`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const deleteDeviceImage = createAsyncThunk(
  "deleteDeviceImage",
  async (
    { deviceImageId, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId } = getState().adminActions.deschutes;
    if (requestId !== currentRequestId) {
      return;
    }
    try {
      const siteName = encodeURI(
        deviceImageId.split("/sites/")[1].split("/")[0],
      );
      const imageId = deviceImageId.split("/deviceImages/")[1];

      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/sites/${siteName}/window365/deviceImages/${imageId}`,
        method: HTTP_METHODS.DELETE,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant, deviceImageId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createDeviceImage = createAsyncThunk(
  "createDeviceImage",
  async (
    { organizationId, tenantId, siteName, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId } = getState().adminActions.deschutes;
    if (requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}/window365/deviceImages`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant: tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getGroups = createAsyncThunk(
  "getGroups",
  async (
    { useCache = true, selectedTenant, organizationId, size, filter, signal },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, groups, groupsSkipTokens } =
      getState().adminActions.deschutes;
    if (currentRequestId && requestId !== currentRequestId) {
      return;
    }

    if (
      useCache &&
      groups &&
      groups[selectedTenant] &&
      groupsSkipTokens[selectedTenant] !== "fetchComplete"
    ) {
      return {
        data: groups[selectedTenant],
        selectedTenant,
        size,
        cachedData: true,
      };
    }

    if (groupsSkipTokens[selectedTenant] === "fetchComplete") {
      return {
        data: groups[selectedTenant],
        selectedTenant,
        size,
        cachedData: true,
      };
    }

    let getGroupsURL = `/organizations/${organizationId}/tenants/${selectedTenant}/groups?${filter || ""}`;

    try {
      const response = await new HTTPClient({
        endpoint: getGroupsURL,
        method: HTTP_METHODS.GET,
        signal,
      }).callAuthorizedAPI();

      return {
        data: response.data,
        selectedTenant,
        size,
        cachedData: false,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getGroupMembers = createAsyncThunk(
  "getGroupMembers",
  async (
    { selectedTenant, organizationId, groupIds },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingGroupMembers } =
      getState().adminActions.deschutes;
    if (!loadingGroupMembers || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/groups/members`,
        method: HTTP_METHODS.POST,
        data: groupIds,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deschutesSlice = createSlice({
  name: "deschutes",
  initialState: {
    selectedSite: "",
    credentialsType: "",
    username: "",
    password: "",
    healthCheckStatus: "",
    selectedConnection: "",
    adRegion: "",
    vnetRegion: "",
    adVnet: "",
    imageName: "",
    osName: "",
    deviceImageVersion: "",
    sourceImageResourceId: "",
    siteName: "",
    selectedDeviceImage: "",
    selectedRegion: "",
    selectedGroup: "",
    skipToken: "",
    loadingDeviceImages: false,
    loadingGalleryImages: false,
    deviceImages: {},
    deviceImagesForSite: {},
    galleryImages: [],
    loadingGroupMembers: false,
    groupMembers: [],
    cloudPcEnabled: {},
    loadingCheckCloudPc: false,
    deviceImagesAvailable: {},
    deletingDeviceImage: null,
    deleteDeviceImageError: null,
    creatingDeviceImage: null,
    createDeviceImageError: "",
    sourceImages: {},
    loadingSourceImages: null,
    sourceImagesError: "",
    groups: {},
    groupsSkipTokens: {},
    loadingGroups: false,
  },
  reducers: {
    resetErrors: (state) => {
      state.error = null;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setCredentialsType: (state, action) => {
      state.credentialsType = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setHealthCheckStatus: (state, action) => {
      state.healthCheckStatus = action.payload;
    },
    setSelectedconnection: (state, action) => {
      state.selectedConnection = action.payload;
    },
    setAdRegion: (state, action) => {
      state.adRegion = action.payload;
    },
    setVnetRegion: (state, action) => {
      state.vnetRegion = action.payload;
    },
    setAdVnet: (state, action) => {
      state.adVnet = action.payload;
    },
    setSelectedImageName: (state, action) => {
      state.imageName = action.payload;
    },
    setOSName: (state, action) => {
      state.osName = action.payload;
    },
    setSelectedDeviceImage: (state, action) => {
      state.selectedDeviceImage = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    setDeviceImageVersion: (state, action) => {
      state.deviceImageVersion = action.payload;
    },
    setSourceImageResourceId: (state, action) => {
      state.sourceImageResourceId = action.payload;
    },
    setSiteName: (state, action) => {
      state.siteName = action.payload;
    },
    resetErrorStatus: (state) => {
      state.createStatus = null;
      state.createDeviceImageError = null;
    },
  },
  extraReducers: {
    [getDeviceImages.pending]: (state, action) => {
      state.loadingDeviceImages = true;
      state.currentRequestId = action.meta.requestId;
      state.deleteDeviceImageError = null;
      state.error = null;
    },
    [getDeviceImages.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingDeviceImages = false;
      if (action.payload.selectedTenant) {
        _.set(
          state.deviceImages,
          `${action.payload.selectedTenant}`,
          action.payload.data,
        );
        _.set(
          state.deviceImagesAvailable,
          `${action.payload.selectedTenant}`,
          true,
        );
      }
    },
    [getDeviceImages.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingDeviceImages = false;
    },
    [deleteDeviceImage.pending]: (state, action) => {
      state.deletingDeviceImage = true;
      state.deleteDeviceImageError = null;
      state.currentRequestId = action.meta.requestId;
    },
    [deleteDeviceImage.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.deletingDeviceImage = false;
      state.deleteDeviceImageError = "";
      if (action.payload.selectedTenant) {
        state.deviceImages[action.payload.selectedTenant] = state.deviceImages[
          action.payload.selectedTenant
        ].filter((i) => i.id !== action.payload.deviceImageId);
      }
    },
    [checkCloudPc.pending]: (state, action) => {
      state.loadingCheckCloudPc = true;
      state.currentRequestId = action.meta.requestId;
    },
    [checkCloudPc.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingCheckCloudPc = false;
      state.cloudPcEnabled[action.payload.selectedTenant] = action.payload
        ? action.payload.data
        : false;
    },
    [checkCloudPc.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingCheckCloudPc = false;
    },
    [getDeviceImagesForSite.pending]: (state, action) => {
      state.loadingDeviceImages = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getDeviceImagesForSite.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingDeviceImages = false;
      state.deviceImagesForSite[action.payload.site] = action.payload
        ? action.payload.data
          ? formDropdownData(
              action.payload.data,
              "displayName",
              "deviceImageId",
            )
          : []
        : [];
    },
    [getDeviceImagesForSite.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingDeviceImages = false;
    },
    [getGalleryImages.pending]: (state, action) => {
      state.loadingGalleryImages = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getGalleryImages.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.galleryImages = formDropdownData(
        action.payload.data || [],
        "imageDisplayName",
        "imageId",
      );
      state.loadingGalleryImages = false;
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingGalleryImages = false;
    },
    [getGroups.pending]: (state, action) => {
      state.loadingGroups = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      if (!action.payload.cachedData) {
        if (action.payload.size) {
          state.groupsSkipTokens[action.payload.selectedTenant] =
            action.payload?.data.data?.odataNextLink === ""
              ? "fetchComplete"
              : action.payload?.data?.data?.odataNextLink;

          state.skipToken = action.payload?.data.data?.odataNextLink;
        }
        if (action.payload?.data?.data.value) {
          state.groups[action.payload.selectedTenant] = formDropdownData(
            action.payload.data.data.value,
            "displayName",
            "groupId",
          );
        } else {
          state.groups[action.payload.selectedTenant] = null;
        }
      }
      state.loadingGroups = false;
    },
    [getGroups.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingGroups = false;
    },
    [getGroupMembers.pending]: (state, action) => {
      state.loadingGroupMembers = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getGroupMembers.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingGroupMembers = false;
      let members = [];
      action.payload?.data.map((data) => {
        members = members.concat(data.members);
      });
      members = _(members)
        .groupBy("id")
        .map((objs) => _.assignWith({}, ...objs, (val1, val2) => val1 || val2))
        .value();
      state.groupMembers = formDropdownData(
        members,
        "userPrincipalName",
        "id",
        true,
      );
    },
    [getGroupMembers.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingGroupMembers = false;
    },
    [deleteDeviceImage.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.deleteDeviceImageError = action.payload;
      state.deletingDeviceImage = null;
    },
    [createDeviceImage.pending]: (state, action) => {
      state.creatingDeviceImage = true;
      state.createDeviceImageError = null;
      state.createStatus = null;
      state.currentRequestId = action.meta.requestId;
    },
    [createDeviceImage.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.creatingDeviceImage = false;
      state.createDeviceImageError = null;
      state.createStatus = true;
      if (action.payload.selectedTenant) {
        if (state.deviceImages[action.payload.selectedTenant]) {
          state.deviceImages[action.payload.selectedTenant].push(
            action.payload.data,
          );
        } else {
          state.deviceImages[action.payload.selectedTenant] = [
            action.payload.data,
          ];
        }
      }
    },
    [createDeviceImage.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.createStatus = false;
      state.createDeviceImageError = action.payload;
      state.creatingDeviceImage = null;
    },
    [getSourceImages.pending]: (state, action) => {
      state.loadingSourceImages = true;
      state.currentRequestIdSourceImages = action.meta.requestId;
      state.sourceImagesError = "";
    },
    [getSourceImages.fulfilled]: (state, action) => {
      state.currentRequestIdSourceImages = null;
      state.loadingSourceImages = false;
      state.sourceImagesError = "";
      if (action.payload.selectedTenant && action.payload.siteName) {
        _.set(
          state.sourceImages,
          `${action.payload.selectedTenant}.${action.payload.siteName}`,
          action.payload.data,
        );
      }
    },
    [getSourceImages.rejected]: (state, action) => {
      state.currentRequestIdSourceImages = null;
      state.sourceImagesError = action.payload;
      state.loadingSourceImages = false;
    },
  },
});

export const {
  setSelectedSite,
  setCredentialsType,
  setUsername,
  setPassword,
  setHealthCheckStatus,
  setSelectedconnection,
  setAdRegion,
  setVnetRegion,
  setAdVnet,
  setSelectedImageName,
  setOSName,
  setSelectedDeviceImage,
  setSelectedRegion,
  setSelectedGroup,
  setDeviceImageVersion,
  setSourceImageResourceId,
  setSiteName,
  resetErrorStatus,
  resetErrors,
} = deschutesSlice.actions;

export default deschutesSlice.reducer;
