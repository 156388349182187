import { Grid } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "@veneer/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import VerticalStepper from "../../../../common/components/VerticalStepper";
import useCurrentTenant from "../../../../common/hooks/useCurrentTenant";
import { getIdentityId, getTenantId } from "../../../../common/utils";
import { useErrorHandling } from "../../hooks/useErrorHandling";
import { getTemplates, getUsers, resetAddPoolVsphere } from "../../poolsSlice";
import {
  useCurrentStep,
  useVsphereStepContent,
  useVsphereStepsMetaData,
} from "./addPoolVsphereFormSettings";

export default function AddPoolsVsphere() {
  const currentTenant = useCurrentTenant();
  const selectedSiteLabel = useSelector(
    (state) => state.adminActions.pools?.selectedSite?.label,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const selectedIdentity = useSelector(
    (state) => state.identities.selectedIdentity,
  );
  const siteName = useSelector(
    (state) => state.adminActions.pools?.selectedSite?.label,
  );
  const tenantId = getTenantId(selectedPartner);
  const organizationId = getIdentityId(selectedIdentity);
  const { stepsMetadata, stepErrors } = useVsphereStepsMetaData();
  const currentStep = useCurrentStep();

  const { errorMessages, handleError, resetErrors } = useErrorHandling();

  useEffect(() => {
    if (!currentTenant?.id || !selectedSiteLabel) {
      history.push("/dashboard/pools");
    }
  }, [currentTenant?.id, history, selectedSiteLabel]);

  useEffect(() => {
    if (tenantId && organizationId) {
      dispatch(getTemplates({ tenantId, organizationId, siteName }))
        .then(unwrapResult)
        .catch((error) => handleError(error, "templates"));

      dispatch(getUsers({ tenantId, organizationId, useCache: true }))
        .then(unwrapResult)
        .catch((error) => handleError(error, "users"));
    }

    return () => dispatch(resetAddPoolVsphere());
  }, [tenantId, organizationId, siteName, dispatch, handleError]);

  const [messagesAlreadyNotified, setMessagesAlreadyNotified] = useState([]);

  useEffect(() => {
    if (errorMessages.length > 0) {
      const newMessages = errorMessages.filter(
        (item) => !messagesAlreadyNotified.includes(item),
      );
      if (newMessages.length > 0) {
        setMessagesAlreadyNotified((prevMessages) => [
          ...prevMessages,
          ...newMessages,
        ]);
      }
      newMessages.map((message) =>
        addToast({
          text: message,
          type: "negative",
          timeout: 6,
          id: Date.now(),
        }),
      );
    }
  }, [addToast, errorMessages, messagesAlreadyNotified, resetErrors]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={7}>
          <VerticalStepper
            stepsMetadata={stepsMetadata}
            getStepContent={useVsphereStepContent()}
            showReset={false}
            lastSessionActiveStep={currentStep}
            confirmOnExit={false}
            defaultCancelPath="/dashboard/pools"
            stepErrors={stepErrors}
          />
        </Grid>
      </Grid>
    </>
  );
}
