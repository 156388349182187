/* eslint-disable react/display-name */
import { useToast } from "@veneer/core";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SelectGroupsPagination from "../../../../common/SelectGroupsPaginated";
import {
  HYPERVISOR_TYPES,
  RESOURCE_POOL_TYPE,
} from "../../../../common/constants";
import { useOrgAndTenantId } from "../../../../common/hooks/useOrgAndTenantId";
import { statusMessages } from "../../../../common/utils";
import {
  checkExistingPool,
  composeValidators,
  required,
} from "../../../../common/validators";
import SelectDeviceImage from "../../../../onboarding/components/SelectDeviceImage";
import PoolNameForm from "../../../../onboarding/components/poolNameForm";
import { validatePoolName } from "../../../../onboarding/onboardingUtils";
import {
  createPool,
  setAddPoolNameAzure,
  setAssignLicenseAzure,
  setDeviceImageAzure,
  setEntraGroupsAzure,
} from "../../poolsSlice";
import { findLastTrueWithAllPreviousTrue } from "../../poolsUtils";

//validates whether the form is enabled to move to the next step
const useCreatePoolAzureValidation = () => {
  const { name, deviceImage, entraGroups, assignLicense } = useSelector(
    (state) => state.adminActions.pools.addPoolAzure,
  );

  const { pools } = useSelector((state) => state.adminActions.pools);

  const { tenantId } = useOrgAndTenantId();
  const tenantPools = pools[tenantId] || [];

  const poolExists = Boolean(checkExistingPool(tenantPools)(name));

  const isNameValid =
    !composeValidators(required, validatePoolName)(name) && !poolExists;
  const isDeviceImageValid = !!deviceImage;
  const isTargetEntraGroupsValid = !!(!!entraGroups && assignLicense);

  const validSteps = {
    isNameValid,
    isDeviceImageValid,
    isTargetEntraGroupsValid,
  };

  validSteps.isValidToCreatePool = Object.values(validSteps).every(
    (i) => i === true,
  );

  return validSteps;
};

export const useAzureGetStepContent = () => {
  const deviceImageFormRef = useRef();
  const groupFormRef = useRef();
  const dispatch = useDispatch();
  const { name, deviceImage, entraGroups } = useSelector(
    (state) => state.adminActions.pools.addPoolAzure,
  );

  //This function updates the redux state, it takes the data that the user is changing and which function should be executed
  const formUpdater = {
    poolNameForm: (data) => {
      dispatch(setAddPoolNameAzure(data?.name));
    },
    selectDeviceImage: (data) => {
      dispatch(setDeviceImageAzure(data));
    },
    targetEntraGroupsForm: (data) => {
      dispatch(setEntraGroupsAzure(data));
    },
  };

  const handleChange = (data, form) => {
    const updateForm = formUpdater[form];
    updateForm(data);
  };

  return (stepNumber) => {
    switch (stepNumber) {
      case 0:
        return (
          <PoolNameForm onChange={handleChange} initialValues={{ name }} />
        );
      case 1:
        return (
          <SelectDeviceImage
            onSubmit={(e) => handleChange(e, "selectDeviceImage")}
            ref={deviceImageFormRef}
            initialValues={{
              deviceImage,
            }}
            onChange={(e) => handleChange(e, "selectDeviceImage")}
            setFormValues={() => {}}
            description="Select the appropriate device image. You can either use a custom image (created above) or an Azure gallery image."
          />
        );
      case 2:
        return (
          <SelectGroupsPagination
            onChange={(e) =>
              handleChange(
                e.map((option) => option.name),
                "targetEntraGroupsForm",
              )
            }
            ref={groupFormRef}
            initialValues={{
              group: entraGroups,
            }}
            securityEnabled={true}
            onFormValidityChange={(isValid) => {
              dispatch(setAssignLicenseAzure(isValid));
            }}
            formName={"Select target Entra groups"}
            setFormValues={() => {}}
            description={
              "Select all the appropriate Entra groups which contain the users to be assigned to this provisioning policy."
            }
          />
        );
      default:
        return null;
    }
  };
};

//data for creating the step, enabling the button to move to next step(based on the validator), configuring the header, and customizing the button label
export const useAzureGetStepsMetaData = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const [stepErrors, setStepErrors] = useState({});

  const { name, entraGroups, deviceImage } = useSelector(
    (state) => state.adminActions.pools.addPoolAzure,
  );
  const selectedSite = useSelector(
    (state) => state.adminActions.pools.selectedSite,
  );
  const dispatch = useDispatch();
  const validatorForm = useCreatePoolAzureValidation();

  const { organizationId, tenantId } = useOrgAndTenantId();

  const handleCreatePool = async () => {
    const payload = {
      name: name,
      resourcePoolType: RESOURCE_POOL_TYPE.VDI,
      site: selectedSite.label,
      deviceImage: deviceImage.label,
      groups: entraGroups,
      hypervisorType: HYPERVISOR_TYPES.CLOUDPC,
    };

    const resultAction = await dispatch(
      createPool({ organizationId, tenantId, payload }),
    );

    return resultAction;
  };

  const stepsMetadata = [
    {
      heading: "Enter pool name",
      canMoveToNextStep: validatorForm.isNameValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select device image",
      canMoveToNextStep: validatorForm.isDeviceImageValid,
      onStepSubmit: async () => {},
    },
    {
      heading: "Select target Entra groups",
      canMoveToNextStep:
        validatorForm.isValidToCreatePool &&
        validatorForm.isTargetEntraGroupsValid,
      stepSubmitButtonLabel: "Create pool",
      onStepSubmit: async () => {
        setStepErrors({});

        try {
          const { meta, payload, error } = await handleCreatePool();
          if (meta.requestStatus === "fulfilled") {
            setStepErrors((prevErrors) => ({ ...prevErrors, 2: false }));
            history.push("/dashboard/pools");
            return { error: false };
          }
          if (meta.requestStatus === "rejected") {
            setStepErrors((prevErrors) => ({ ...prevErrors, 2: true }));
            const messageError =
              payload?.detail ||
              error?.message ||
              statusMessages[payload?.status] ||
              "An error occurred while creating the pool.";
            addToast({
              text: messageError,
              type: "negative",
              timeout: 6,
              id: Date.now(),
            });
            return { error: true };
          }
          return { error: true };
        } catch (error) {
          addToast({
            text: `Failed to create pool: ${error}`,
            type: "negative",
            timeout: 6,
            id: Date.now(),
          });
          setStepErrors((prevErrors) => ({ ...prevErrors, 2: true }));
          return { error: true };
        }
      },
    },
  ];

  return {
    stepsMetadata,
    stepErrors,
  };
};

//identifies the most recent step if the user has already started filling out the form.
export const useCurrentStep = () => {
  const validatorForm = useCreatePoolAzureValidation();
  const currentStep = useMemo(
    () => findLastTrueWithAllPreviousTrue(Object.values(validatorForm)),
    [],
  );
  return currentStep;
};
