import {
  IconCheckmarkCircle,
  IconClock,
  IconInfo,
  IconKeySequence,
  IconPendingJob,
  IconWarning,
  IconXCircle,
} from "@veneer/core";

const STATUS_MAP = {
  available: {
    statuses: ["available"],
    type: "positive",
    label: "Available",
    icon: <IconCheckmarkCircle size={16} filled />,
    cardIcon: <IconCheckmarkCircle color="primary" />,
  },
  preparingPool: {
    statuses: ["creating", "needmoreservers"],
    type: "neutral",
    label: "Preparing pool",
    icon: <IconInfo size={16} filled />,
    cardIcon: <IconKeySequence color="primary" />,
  },
  pending: {
    statuses: ["unknown"],
    type: "disabled",
    label: "Pending",
    icon: <IconClock filled />,
    cardIcon: <IconPendingJob color="primary" />,
  },
  actionRequired: {
    statuses: [
      "servercreationfailed",
      "toomanyservers",
      "mustupdateimage",
      "createfailed",
    ],
    type: "warning",
    label: "Action required",
    icon: <IconWarning size={16} filled />,
  },
  preparingMachines: {
    statuses: ["creatingservers"],
    type: "neutral",
    label: "Preparing machines",
    icon: <IconInfo size={16} filled />,
    cardIcon: <IconClock color="primary" />,
  },
  deletingServer: {
    statuses: ["deletingservers"],
    type: "negative",
    label: "Deleting",
    icon: <IconXCircle size={16} filled />,
  },
};

export const ACTION_REQUIRED_TOOLTIP_TEXT = {
  servercreationfailed: "Machines creation failed",
  toomanyservers: "Too many machines",
  mustupdateimage: "Image needs to be updated",
  createfailed: "Creation failed",
};

const findStatusKey = (status) => {
  const statusKeys = Object.keys(STATUS_MAP);
  return statusKeys.find((key) =>
    STATUS_MAP[key].statuses.includes(String(status).toLowerCase()),
  );
};

export const mapPoolsTagType = (status) => {
  const statusKey = findStatusKey(status);
  return STATUS_MAP[statusKey]?.type || "disabled";
};

export const mapPoolsTagLabel = (status) => {
  const statusKey = findStatusKey(status);
  return STATUS_MAP[statusKey]?.label || "Unknown";
};

export const mapPoolsTagIcon = (status) => {
  const statusKey = findStatusKey(status);
  return STATUS_MAP[statusKey]?.icon || undefined;
};

export const mapPoolsCardIcon = (status) => {
  return STATUS_MAP[status]?.cardIcon || undefined;
};
