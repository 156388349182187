import { styled } from "@mui/material/styles";
import { Accordion, Card, Group, IconCheckmark, Tag } from "@veneer/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { WizardTag } from "../../dashboard/components/tags/WizardTag";
import CommonModal from "./CommonModal";
import VeneerButton from "./VeneerButton";

const Root = styled("div")(() => ({
  width: "100%",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  ["& > div > div > svg"]: {
    display: "none",
  },

  ['& [role="button"][aria-expanded]']: {
    cursor: "default",
  },

  ['& [aria-expanded="true"]']: {
    border: `solid 1px ${theme.palette.primary.main}`,
    borderBottom: "none",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  },

  ['& [aria-expanded="true"] + div']: {
    paddingLeft: "32px",
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "12px",
    borderTop: "none",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  },

  ['& [aria-hidden="false"]']: {
    border: "none",
  },
}));

const StyledTag = styled(Tag)(() => ({
  ["& span"]: {
    lineHeight: "12px",
    margin: "0px auto",
    textAlign: "center",
    width: "22px",
  },
}));

const StyledGroup = styled(Group)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingRight: "0px",
  paddingBottom: "0px",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

const mapHeadingToResourceName = (heading) => {
  switch (heading) {
    case "Tenant Details":
      return "tenant";
    default:
      return "resource";
  }
};

const VerticalStepperControls = React.forwardRef(
  ({
    onClickCancel,
    onClickNext,
    onClickPrevious,
    disableButtonNext,
    disableButtonPrev,
    buttonNextLabel,
  }) => {
    return (
      <StyledGroup spacing={4}>
        <VeneerButton appearance="ghost" onClick={onClickCancel}>
          Cancel
        </VeneerButton>
        <VeneerButton
          appearance="secondary"
          onClick={onClickPrevious}
          disabled={disableButtonPrev}
        >
          Previous
        </VeneerButton>
        <VeneerButton
          appearance="primary"
          onClick={onClickNext}
          disabled={disableButtonNext}
        >
          {buttonNextLabel}
        </VeneerButton>
      </StyledGroup>
    );
  },
);
VerticalStepperControls.displayName = "VerticalStepperControls";

const VerticalStepper = React.forwardRef(
  (
    {
      showReset = true,
      showRedirection = false,
      completionMessage,
      handleRedirection,
      completionButtonTitle,
      stepErrors = [],
      onCancel,
      defaultCancelPath = "/admin",
      setErrors,
      azureAccountLinkingStatus,
      stepsMetadata,
      lastSessionActiveStep,
      ...props
    },
    ref,
  ) => {
    const history = useHistory();

    const [activeStep, setActiveStep] = useState(lastSessionActiveStep || 0);

    const [navigateAway, setNavigateAway] = useState(false);
    const [nextLocation, setNextLocation] = useState(defaultCancelPath);

    const [stepLoading, setStepLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [stepWithError, setStepWithError] = useState(null);
    const [successfulSteps, setSuccessfulSteps] = useState([]);

    const steps = useMemo(
      () => (stepsMetadata || []).filter((step) => !step.hide),
      [stepsMetadata],
    );

    const isFirstStep = useMemo(() => activeStep === 0, [activeStep]);
    const isLastStep = useMemo(
      () => activeStep === steps.length - 1,
      [activeStep, steps.length],
    );

    const getStepAttributes = useCallback(
      (index) => {
        const stepLabel = `${index + 1}`;

        if (stepErrors[index]) {
          return {
            status: "error",
            color: "red",
            label: stepLabel,
            enableContent: true,
          };
        }

        if (azureAccountLinkingStatus && index === 1) {
          return {
            status: "success",
            color: "green",
            label: <IconCheckmark size={14} color="white" />,
            enableContent: false,
          };
        }

        if (successfulSteps.includes(index)) {
          return {
            status: "success",
            color: "green",
            label: <IconCheckmark size={14} color="white" />,
            enableContent: false,
          };
        }

        if (index < activeStep) {
          setSuccessfulSteps((prev) =>
            prev.includes(index) ? prev : [...prev, index],
          );
          return {
            status: "success",
            color: "green",
            label: <IconCheckmark size={14} color="white" />,
            enableContent: false,
          };
        }

        if (stepLoading && index === activeStep) {
          return {
            status: "loading",
            color: "gray",
            label: stepLabel,
            enableContent: false,
          };
        }

        if (index === activeStep) {
          return {
            status: "active",
            color: "darkNavy",
            label: stepLabel,
            enableContent: true,
          };
        }

        return { status: "pending", color: "gray", label: stepLabel };
      },
      [
        activeStep,
        azureAccountLinkingStatus,
        stepErrors,
        stepLoading,
        successfulSteps,
      ],
    );

    const stepStatus = useCallback(
      (index) => getStepAttributes(index).status,
      [getStepAttributes],
    );

    const stepColor = useCallback(
      (index) => getStepAttributes(index).color,
      [getStepAttributes],
    );

    const stepLabel = useCallback(
      (index) => getStepAttributes(index).label,
      [getStepAttributes],
    );

    const stepContent = useCallback(
      (index) => getStepAttributes(index).enableContent,
      [getStepAttributes],
    );

    useEffect(() => {
      if (Number.isInteger(lastSessionActiveStep)) {
        setActiveStep(lastSessionActiveStep);
      }
    }, [lastSessionActiveStep]);

    const shouldShowModal = useCallback(() => {
      return (
        typeof steps[activeStep]?.hasPendingChanges === "function" &&
        steps[activeStep]?.hasPendingChanges(activeStep)
      );
    }, [activeStep, steps]);

    const markStepAsSuccessful = useCallback((prevActiveStep) => {
      setSuccessfulSteps((prevSuccessfulSteps) => [
        ...prevSuccessfulSteps,
        prevActiveStep,
      ]);
    }, []);

    const advanceStep = useCallback(() => {
      setActiveStep((prevActiveStep) => {
        markStepAsSuccessful(prevActiveStep);

        if (stepWithError === prevActiveStep + 1) {
          setStepWithError(null);
        }

        return prevActiveStep + 1;
      });
    }, [markStepAsSuccessful, stepWithError]);

    const handleError = useCallback(
      (e) => {
        const isValidToNextStep = !e?.error || e === undefined;
        if (isValidToNextStep && activeStep < steps.length - 1) {
          advanceStep();
        }
      },
      [activeStep, advanceStep, steps.length],
    );

    const handleNext = useCallback(async () => {
      const step = steps[activeStep];

      if (step.onStepSubmit) {
        setStepLoading(true);

        if (setErrors) {
          setErrors(activeStep, false);
        }

        try {
          const result = await step.onStepSubmit();
          handleError(result);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error in step submission:", error);
        }

        setStepLoading(false);
      } else {
        advanceStep();
      }
    }, [activeStep, advanceStep, handleError, setErrors, steps]);

    const handleBack = useCallback(() => {
      if (stepStatus(activeStep) === "error") {
        setStepWithError(null);
        if (setErrors) {
          setErrors(activeStep, false);
        }
      }

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [activeStep, setErrors, stepStatus]);

    const handleReset = () => {
      setActiveStep(0);
    };

    const handleCancel = useCallback(() => {
      if (!showModal && shouldShowModal()) {
        setShowModal(true);
        return;
      }

      if (nextLocation) {
        setNavigateAway(true);
        history.push(nextLocation.pathname);
      }

      history.push(nextLocation);

      if (onCancel) {
        onCancel();
      }
    }, [history, nextLocation, onCancel, shouldShowModal, showModal]);

    const handleRouteChange = (location) => {
      if (!navigateAway && shouldShowModal()) {
        setShowModal(true);
        setNextLocation(location);

        return false;
      }

      return true;
    };

    const hideModal = () => {
      setShowModal(false);
      setNextLocation("/admin");
    };

    const handleEnableContent = useCallback(
      (index) => {
        const status = stepStatus(index);

        if (
          (index === stepWithError && status === "error") ||
          (index === activeStep && status === "loading")
        ) {
          return false;
        }

        if (index === activeStep) {
          return true;
        }

        return activeStep >= 0 && stepContent(index);
      },
      [activeStep, stepContent, stepStatus, stepWithError],
    );

    const stepAttributeToStatusMap = useMemo(
      () => ({
        error: "error",
        success: "completed",
        loading: "processing",
        active: "inProgress",
        pending: "notStarted",
      }),
      [],
    );

    useEffect(() => {
      if (navigateAway && nextLocation) {
        history.push(nextLocation.pathname);
      }
    }, [navigateAway, nextLocation, history]);

    const modalMessage = useMemo(() => {
      if (isFirstStep) {
        return `Are you sure you want to cancel adding a new ${mapHeadingToResourceName(steps[0].heading)}? Information you have entered so far will not be saved. Complete the first step to create a draft record you can complete later.`;
      }

      return "Are you sure you want to leave this screen? Unsaved progress will be lost.";
    }, [isFirstStep, steps]);

    const commonModalActions = [
      {
        label: isFirstStep ? "Exit" : "Leave",
        main: true,
        context: "error",
        onClick: handleCancel,
        id: "modal-exit-button",
      },
      {
        label: isFirstStep ? "Keep editing" : "Cancel",
        context: "info",
        onClick: hideModal,
        id: "modal-keep-editing-button",
      },
    ];

    const data = useMemo(() => {
      return steps.map((stepMetaData, index, allSteps) => {
        const { heading, hideAction, stepSubmitButtonLabel } = stepMetaData;

        const isCurrentStep = activeStep === index;
        const isFowardStep = activeStep < index;
        const isLastEnabledStep = index === allSteps.length - 1;

        const canMoveToNextStep =
          stepMetaData.canMoveToNextStep === undefined ||
          stepMetaData.canMoveToNextStep;
        const canMoveToPreviousStep =
          stepMetaData.canMoveToPreviousStep === undefined ||
          stepMetaData.canMoveToPreviousStep;

        const showContent = isCurrentStep && handleEnableContent(index);

        return {
          id: index,
          expanded: showContent,
          disabled: isFowardStep,
          header: {
            startArea: (
              <StyledTag
                label={stepLabel(index)}
                colorScheme={stepColor(index)}
                disabled={isFowardStep}
              />
            ),
            centralArea: heading,
            endArea: (
              <WizardTag status={stepAttributeToStatusMap[stepStatus(index)]} />
            ),
          },
          content: showContent ? (
            <>
              {props.getStepContent(index)}
              <VerticalStepperControls
                onClickCancel={handleCancel}
                onClickNext={handleNext}
                onClickPrevious={handleBack}
                disableButtonNext={
                  !hideAction && (stepLoading || !canMoveToNextStep)
                }
                disableButtonPrev={
                  !hideAction &&
                  (isFirstStep || stepLoading || !canMoveToPreviousStep)
                }
                buttonNextLabel={
                  isLastEnabledStep ? stepSubmitButtonLabel || "Submit" : "Next"
                }
              />
            </>
          ) : null,
        };
      });
    }, [
      activeStep,
      handleBack,
      handleCancel,
      handleEnableContent,
      handleNext,
      isFirstStep,
      props,
      stepAttributeToStatusMap,
      stepColor,
      stepLabel,
      stepLoading,
      stepStatus,
      steps,
    ]);

    return (
      <Root ref={ref} role="region" aria-labelledby="stepper-heading">
        <StyledAccordion
          gutter="8px"
          behavior="multiExpand"
          background="filled"
          border="outlined"
          items={data}
        />
        {showReset && isLastStep && (
          <Card
            customStyle={{ padding: "16px" }}
            role="alert"
            aria-live="assertive"
            background="filled"
            border="outlined"
            content={
              <>
                <p>All steps completed - you&apos;re finished</p>
                <VeneerButton
                  appearance="primary"
                  onClick={handleReset}
                  data-testid={"reset"}
                >
                  Reset
                </VeneerButton>
              </>
            }
          />
        )}
        {showRedirection && isLastStep && (
          <Card
            customStyle={{ padding: "16px" }}
            role="alert"
            aria-live="assertive"
            background="filled"
            border="outlined"
            content={
              <>
                <p>{completionMessage}</p>
                <VeneerButton
                  appearance="primary"
                  onClick={handleRedirection}
                  data-testid={"redirect"}
                >
                  {completionButtonTitle}
                </VeneerButton>
              </>
            }
          />
        )}
        <Prompt
          when={shouldShowModal()}
          message={(location) => handleRouteChange(location)}
        />
        <CommonModal
          title="Leave screen"
          open={showModal}
          onClose={hideModal}
          actions={commonModalActions}
        >
          {modalMessage}
        </CommonModal>
      </Root>
    );
  },
);

VerticalStepper.displayName = "VerticalStepper";

VerticalStepper.propTypes = {
  stepsMetadata: PropTypes.array.isRequired,
  getStepContent: PropTypes.func.isRequired,
  showReset: PropTypes.bool,
  hideAction: PropTypes.bool,
  showRedirection: PropTypes.bool,
  completionMessage: PropTypes.string,
  completionButtonTitle: PropTypes.string,
  handleRedirection: PropTypes.func,
  confirmOnExit: PropTypes.bool,
  stepErrors: PropTypes.object,
  onCancel: PropTypes.func,
  defaultCancelPath: PropTypes.string,
};

export default VerticalStepper;
