import { IconCheckmarkCircle, IconClock, IconXCircle } from "@veneer/core";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "../../../onboarding/components/Spinner";
import { TagIndicator } from "./TagIndicator";

// Status mapping
const STATUS_MAP = {
  available: {
    type: "positive",
    label: "Available",
    icon: <IconCheckmarkCircle size={16} filled />,
  },
  failed: {
    type: "negative",
    label: "Failed",
    icon: <IconXCircle size={16} filled />,
  },
  deleteFailed: {
    type: "negative",
    label: "Delete Failed",
    icon: <IconXCircle size={16} filled />,
  },
  creating: {
    type: "disabled",
    label: "Pending",
    icon: <IconClock filled />,
  },
  deleting: {
    type: "disabled",
    label: "Deleting",
    icon: <Spinner />,
  },
};

const mapStatusType = (status) => STATUS_MAP[status]?.type || "disabled";
const mapStatusLabel = (status) => STATUS_MAP[status]?.label || "Unknown";
const mapStatusIcon = (status) => STATUS_MAP[status]?.icon || undefined;

export const SitesTag = ({ status }) => {
  const label = mapStatusLabel(status);
  const type = mapStatusType(status);
  const leadingIcon = mapStatusIcon(status);

  return <TagIndicator label={label} type={type} leadingIcon={leadingIcon} />;
};

SitesTag.propTypes = {
  status: PropTypes.oneOf([
    "available",
    "failed",
    "deleteFailed",
    "creating",
    "deleting",
  ]),
};
