import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const createOrganizations = createAsyncThunk(
  "organizations",
  async (values, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().organizations;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      const response = await new HTTPClient({
        endpoint: "/organizations/",
        method: HTTP_METHODS.POST,
        data: formData,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

/**************************************
 ************** Reducers ***************
 ***************************************/

export const initialState = {
  organizations: [],
  requesting: false,
  orgCreated: false,
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setRequesting: (state, action) => {
      state.requesting = action.payload;
    },
    resetData: (state) => {
      state.orgCreated = false;
      state.error = null;
      state.loading = false;
      state.currentRequestId = null;
    },
  },
  extraReducers: {
    [createOrganizations.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    },
    [createOrganizations.fulfilled]: (state) => {
      state.loading = false;
      state.currentRequestId = null;
      state.orgCreated = true;
    },
    [createOrganizations.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
      state.orgCreated = false;
    },
  },
});
export const { resetData } = organizationsSlice.actions;

export default organizationsSlice.reducer;
