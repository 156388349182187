import { styled } from "@mui/material";
import React from "react";
import IconSpinner from "../../icons/icon-spinner.png";

const SpinnerWrapper = styled("img")({
  width: "15px",
  animationName: "spin",
  animationDuration: "1s",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
});

const Spinner = () => {
  return <SpinnerWrapper src={IconSpinner} alt="Spinner" />;
};

export default Spinner;
